import OutboundLink from "@/components/general/OutboundLink";
// import components
import withOutboundPaths from "@/links/withOutboundPaths";
// import selectors
import * as selectors from "@/selectors";
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import LegacyReviewLink from "./LegacyReviewLink";
import TaxCafNumber from "./TaxCafNumber";

const styles = {
	serviceLeftLogoSection: css`
		display: flex;
		width: 100%;
		justify-content: center;
		flex-wrap: wrap;
		text-align: center;

		@media (max-width: 580px) {
			flex-wrap: unset;
		}
	`,
	serviceLeftLinkWrapper: css`
		width: 100%;
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		text-align: center;
		position: relative;
		align-content: center;
		align-items: center;
		top: 0;
		left: 0;
		transform: none;

		@media (max-width: 767px) {
			position: static;
		}
	`,
	logoLink: css`
		width: 100%;
		max-width: 300px;
		text-align: center;

		@media (max-width: 500px) {
			max-width: 100%;
		}
	`,
};

export const LegacyLogoLink = ({
	company,
	companyReviewPath,
	companyVisitPath,
	isCompanyReviewLinkable,
	children,
}) => {
	const isTargetedPath = useSelector(selectors.getIsTargetedPath);
	const isOnlyAllowedToRenderVisitLinks = useSelector(
		selectors.getIsOnlyAllowedToRenderVisitLinks,
	);
	const companyName = useSelector((state) =>
		selectors.getCompanyName(state, { company }),
	);

	const LogoLink = ({ children }) =>
		isTargetedPath || isOnlyAllowedToRenderVisitLinks ? (
			<OutboundLink
				css={styles.logoLink}
				to={companyVisitPath}
				rel="nofollow"
				company={company}
			>
				{children}
			</OutboundLink>
		) : (
			<a
				css={styles.logoLink}
				{...(isCompanyReviewLinkable ? { href: companyReviewPath } : {})}
				aria-label={companyName + " Review"}
			>
				{children}
			</a>
		);

	LogoLink.propTypes = {
		children: PropTypes.oneOfType([
			PropTypes.arrayOf(PropTypes.node),
			PropTypes.node,
		]),
	};

	return (
		<div css={styles.serviceLeftLogoSection}>
			<div css={styles.serviceLeftLinkWrapper}>
				<LogoLink>
					{children}
					<TaxCafNumber company={company} />
				</LogoLink>
			</div>
			{!isOnlyAllowedToRenderVisitLinks && (
				<LegacyReviewLink
					companyReviewPath={companyReviewPath}
					isCompanyReviewLinkable={isCompanyReviewLinkable}
					companyName={companyName}
				/>
			)}
		</div>
	);
};

export default withOutboundPaths(LegacyLogoLink);

LegacyLogoLink.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
	company: PropTypes.object.isRequired,
	companyIndex: PropTypes.number.isRequired,
	companyVisitPath: PropTypes.string.isRequired,
	companyReviewPath: PropTypes.string.isRequired,
	isCompanyReviewLinkable: PropTypes.bool.isRequired,
};
