export const getStyles = ({ companyIndex }) => ({
	featuredCompanyHeader: {
		wrapper:
			"tw-flex tw-justify-center tw-bg-[#059669] tw-px-2 tw-py-2 lg:tw-py-4 lg:tw-px-4",
		contentWrapper:
			"tw-relative tw-max-w-[200px] lg:tw-max-w-none tw-text-center tw-text-base tw-font-bold tw-leading-6 lg:tw-tracking-[-0.4px] tw-text-white lg:tw-text-xl",
		iconClass:
			"-tw-left-[56px] -tw-mt-2 tw-h-14 tw-w-14 lg:-tw-left-[54px] lg:-tw-mt-4 lg:tw-h-12 lg:tw-w-12",
	},
	coupon: {
		wrapper:
			"tw-group tw-flex tw-justify-center tw-bg-[#1c95692b] tw-px-2 tw-py-3 lg:tw-px-4 tw-cursor-pointer",
		contentWrapper: "tw-flex",
		contentIcon:
			"tw-inline-flex tw-mr-2 tw-text-xl tw-text-black lg:tw-text-2xl",
		content:
			"tw-text-center tw-text-sm lg:tw-text-base tw-font-semibold tw-leading-[22px] lg:tw-leading-6 tw-tracking-[-0.16px] lg:tw-tracking-[-0.16px] tw-text-black group-hover:tw-underline",
	},
	serviceFlag: {
		wrapper: "lg:tw-absolute tw-mt-4 lg:tw-mt-0 lg:tw-top-6 tw-flex tw-gap-0.5",
		flag: `tw-w-[28px] tw-h-[28px] ${
			companyIndex === 0 ? "tw-bg-[#059669]" : "tw-bg-[#000F10]"
		} tw-flex tw-items-center tw-justify-center`,
		number: "tw-text-white tw-text-sm tw-font-bold tw-leading-4",
		text: "tw-text-white tw-text-sm tw-bg-[#059669] tw-font-semibold tw-leading-4 tw-flex tw-items-center tw-pl-2.5 tw-pr-4 [clip-path:polygon(100%_0,calc(100%-10px)_100%,0_100%,0_0)]",
	},
	companyLogo: { picture: "", image: "" },
	rating: {
		wrapper:
			"tw-flex tw-items-center tw-gap-1.5 tw-rounded-full tw-bg-white tw-px-4 tw-py-2 tw-shadow-[0px_10px_64px_0px_rgba(50,50,93,0.06),0px_5px_32px_0px_rgba(0,0,0,0.04)]",
		score:
			"tw-text-[28px] tw-font-bold tw-leading-8 tw-tracking-[-1.68px] tw-text-[#213132]",
		stars: "tw-flex tw-flex-col tw-gap-1",
		starColor: "tw-text-[#059669] tw-text-lg",
		count:
			"tw-ml-0.5 tw-text-xs tw-font-semibold tw-leading-[100%] tw-text-[#213132]",
	},
	benefitsList: {
		wrapper: "lg:tw-mt-4",
		list: "tw-flex tw-flex-col tw-gap-1 tw-justify-center",
		listItem:
			"tw-text-[#213132] tw-text-sm lg:tw-text-base tw-font-normal tw-leading-6 tw-flex tw-gap-2",
	},
	socialProof: {
		wrapper:
			"tw-relative tw-flex tw-mb-3.5 tw-rounded-lg tw-max-w-fit tw-bg-white tw-p-2 [filter:drop-shadow(0px_0px_1px_rgba(88,102,126,0.48))_drop-shadow(0px_0px_24px_rgba(88,102,126,0.10))] after:tw-absolute after:tw-right-[calc(50%-6px)] after:tw-w-2.5 after:tw-h-2.5 after:tw-translate-x-0 after:tw--translate-y-2/4 after:tw-rotate-45 after:tw-bg-white after:tw-overflow-hidden after:tw-pointer-events-none after:tw-top-full",
		contentWrapper:
			"tw-flex tw-flex-row tw-justify-center tw-items-center tw-gap-2",
		contentIcon: "tw-text-[#000F10] tw-text-[32px]",
		content:
			"tw-min-w-[102px] tw-max-w-[106px] tw-leading-4 tw-font-medium tw-text-xs tw-text-left tw-text-[#213132]",
	},
	visitLink: {
		wrapper:
			"tw-inline-flex tw-w-full tw-group tw-justify-center tw-p-2 tw-bg-[#059669] tw-rounded-[12px] tw-transition tw-duration-300 tw-gap-1.5 hover:tw-bg-[#036345] tw-cursor-pointer tw-mb-2 lg:tw-py-3 lg:tw-mb-3",
		contentWrapper:
			"tw-text-white tw-font-semibold tw-leading-6 tw-text-base lg:tw-text-lg lg:tw-tracking-[-0.18px]",
		iconClass:
			"tw-hidden tw-text-2xl tw-text-white tw-transition-all tw-duration-300 group-hover:tw-translate-x-1 lg:tw-inline-flex",
	},
	reviewLink: {
		wrapper: "tw-text-center",
		text: "tw-text-[#059669] tw-text-sm lg:tw-text-base tw-font-bold tw-leading-6",
	},
});
