// import components
import OutboundLink from "@/components/general/OutboundLink";
// Import selector(s)
import * as selectors from "@/selectors";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

export const HeaderLink = ({
	company,
	companyReviewPath,
	companyVisitPath,
	isCompanyReviewLinkable,
	isAllLinkingProhibited,
}) => {
	const isTargetedPath = useSelector(selectors.getIsTargetedPath);
	const isOnlyAllowedToRenderVisitLinks = useSelector(
		selectors.getIsOnlyAllowedToRenderVisitLinks,
	);
	const companyName = useSelector((state) =>
		selectors.getCompanyName(state, { company }),
	);

	const targetedOutboundLink = isAllLinkingProhibited ? (
		<a>{companyName}</a>
	) : (
		<OutboundLink to={companyVisitPath} company={company}>
			{companyName}
		</OutboundLink>
	);

	const nonTargetedOutboundLink = (
		<a
			{...(isCompanyReviewLinkable ? { href: companyReviewPath } : {})}
			aria-label={companyName + " Review"}
		>
			{companyName}
		</a>
	);
	return isTargetedPath || isOnlyAllowedToRenderVisitLinks
		? targetedOutboundLink
		: nonTargetedOutboundLink;
};

export default HeaderLink;

HeaderLink.propTypes = {
	company: PropTypes.object.isRequired,
	companyReviewPath: PropTypes.string.isRequired,
	companyVisitPath: PropTypes.string.isRequired,
	isCompanyReviewLinkable: PropTypes.bool.isRequired,
	isAllLinkingProhibited: PropTypes.bool.isRequired,
};
