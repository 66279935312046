import { css } from "@emotion/react";
import tw from "twin.macro";

export const getStyles = ({ companyIndex }) => ({
	featuredCompanyHeader: {
		wrapper:
			"tw-py-2 tw-px-2 tw-flex tw-justify-center tw-bg-[#359492] tw-text-white tw-rounded-t-lg",
		contentWrapper:
			"tw-text-center tw-text-base tw-font-bold tw-leading-6 tw-tracking-[-0.16px]",
	},
	serviceFlag: {
		wrapper: "",
		flag: `tw-absolute tw-top-4 tw-left-4 tw-w-6 tw-h-6 tw-flex tw-items-center tw-justify-center tw-rounded-full lg:tw-w-8 lg:tw-h-8 lg:tw-top-2/4 lg:tw--translate-y-2/4 xl:tw-left-6 ${
			companyIndex === 0
				? "tw-bg-[#616096] tw-text-white"
				: "tw-bg-[#EDEDEE] tw-text-[#22293A]"
		}`,
		number: `tw-text-current tw-text-sm tw-font-medium tw-leading-normal lg:tw-text-base lg:tw-leading-6 lg:tw-top-6`,
		contentWrapper:
			"tw-absolute tw-top-4 tw-left-12 tw-py-1 tw-pl-2 tw-pr-4 tw-flex tw-items-center tw-gap-1 tw-bg-[#2A7675] tw-text-white lg:tw-gap-2 [clip-path:polygon(100%_0,calc(100%_-_10px)_50%,100%_100%,0_100%,0_0)] lg:tw-left-0",
		icon: "tw-inline-flex",
		text: "tw-text-xs tw-font-semibold tw-leading-4",
	},
	companyLogo: { picture: "tw-flex", image: "tw-w-[160px]" },
	rating: {
		wrapper:
			"tw-w-fit tw-inline-flex tw-items-center tw-gap-1.5 tw-rounded-full tw-bg-white tw-py-2.5 tw-px-4 tw-shadow-[0px_4px_24px_0px_rgba(23,23,23,0.08),0px_0px_1px_0px_rgba(23,23,23,0.16)] lg:tw-py-2 lg:tw-pr-4 lg:tw-pl-3.5",
		score:
			"tw-text-2xl tw-font-bold tw-leading-6 tw-tracking-[-0.96px] tw-text-[#22293A] lg:tw-text-[28px] lg:tw-leading-9 lg:tw-tracking-[-1.12px]",
		stars: "tw-flex tw-flex-col tw-gap-1",
		starColor: "!tw-text-[#359492] !tw-text-base lg:!tw-text-lg",
		count: "tw-ml-1 tw-text-xs tw-font-semibold tw-leading-3 tw-text-[#22293A]",
	},
	productDescription:
		"tw-text-pretty tw-text-lg tw-font-bold tw-leading-6 tw-tracking-[-0.18px] tw-text-[#131D2B] lg:tw-text-xl lg:tw-leading-7 lg:tw-tracking-[-0.2px]",
	coupon: {
		wrapper: `tw-h-full tw-p-2 tw-rounded-lg tw-bg-white tw-border-dashed tw-border-[1px] tw-shadow-[0px_0px_1px_rgba(23,23,23,0.16),0px_4px_24px_rgba(23,23,23,0.08)] ${companyIndex === 0 ? " tw-border-[#E1255B]" : "tw-border-[#616096]"}`,
		offerWrapper: `tw-flex tw-justify-center tw-items-center lg:tw-h-[30px] tw-h-[32px] tw-rounded-t-lg ${companyIndex === 0 ? " tw-bg-[#E1255B]" : "tw-bg-[#616096]"}`,
		offerIcon: `tw-inline-flex tw-items-center tw-mr-1.5 tw-shrink-0 tw-text-white ${companyIndex === 0 ? " tw-text-[22px]" : "tw-text-[22px] lg:tw-text-[18px]"}`,
		offerContent: `tw-text-white tw-font-bold tw-uppercase ${companyIndex === 0 ? "lg:tw-text-[12px] lg:tw-leading-[22px] lg:tw-tracking-[0.24px] tw-text-[14px] tw-leading-[22px] tw-tracking-[0.28px]" : "lg:tw-text-[10px] lg:tw-leading-[18px] lg:tw-tracking-[0.2px] tw-text-[12px] tw-leading-[22px] tw-tracking-[0.24px]"}`,
		contentWrapper: `tw-px-2 tw-rounded-b-lg tw-bg-gradient-to-b tw-flex tw-flex-col tw-items-center tw-justify-center tw-flex-grow ${companyIndex === 0 ? " tw-from-[rgba(253,238,242,0)] tw-via-[rgba(253,238,242,0.3542)] tw-to-[#FDEEF2]" : "tw-from-[rgba(245,243,255,0)] tw-via-[rgba(245,243,255,0.3167)] tw-to-[#F5F3FF]"}`,
		desktopLargeContent:
			"tw-hidden lg:tw-flex tw-text-[24px] tw-font-bold tw-text-[#E1255B]",
		desktopContent: `tw-hidden lg:tw-flex tw-max-w-[70%] tw-text-center ${companyIndex === 0 ? " tw-text-[#E1255B] lg:tw-text-[14px] lg:tw-leading-[20px] tw-text-[20px] tw-font-bold tw-leading-[26px] tw--tracking-[0.2px]" : "tw-text-[#616096] lg:tw-text-[14px] lg:tw-leading-[20px] tw-text-[16px] tw-font-semibold tw-leading-6 tw--tracking-[0.16px]"}`,
		mobileContent: `tw-flex lg:tw-hidden tw-max-w-[80%] tw-text-center ${companyIndex === 0 ? " tw-text-[#E1255B] lg:tw-text-[14px] lg:tw-leading-[20px] tw-text-[20px] tw-font-bold tw-leading-[26px] tw--tracking-[0.2px]" : "tw-text-[#616096] lg:tw-text-[14px] lg:tw-leading-[20px] tw-text-[16px] tw-font-semibold tw-leading-6 tw--tracking-[0.16px]"}`,
	},
	benefitsList: {
		wrapper: "tw-mt-4",
		list: "tw-flex tw-flex-col tw-gap-2 tw-justify-center",
		listItem:
			"tw-flex tw-text-[#22293A] tw-text-sm tw-gap-2 tw-leading-6 tw-text-pretty",
	},
	socialProof: {
		wrapper:
			"tw-relative tw-flex tw-mb-3.5 tw-rounded-lg tw-max-w-fit tw-bg-white tw-p-2 [filter:drop-shadow(0px_0px_1px_rgba(88,102,126,0.48))_drop-shadow(0px_0px_24px_rgba(88,102,126,0.10))] after:tw-absolute after:tw-right-[calc(50%-6px)] after:tw-w-2.5 after:tw-h-2.5 after:tw-translate-x-0 after:tw--translate-y-2/4 after:tw-rotate-45 after:tw-bg-white after:tw-overflow-hidden after:tw-pointer-events-none after:tw-top-full lg:tw-mt-3.5 lg:after:tw-top-0",
		contentWrapper:
			"tw-flex tw-flex-row tw-justify-center tw-items-center tw-gap-2",
		contentIcon: "tw-text-[#F68700] tw-text-[32px]",
		content:
			"tw-min-w-[102px] tw-max-w-[106px] tw-leading-4 tw-font-semibold tw-text-[11px] tw-text-left tw-text-[#22293A]",
	},
	visitLink: {
		wrapper:
			"tw-inline-flex tw-w-full tw-group tw-justify-center tw-items-center tw-p-2 tw-bg-[#359492] tw-rounded-lg tw-transition tw-duration-300 tw-gap-2 tw-cursor-pointer hover:tw-bg-[#246161] lg:tw-py-3",
		contentWrapper:
			"tw-text-white tw-font-semibold tw-leading-6 tw-text-sm lg:tw-text-base tw-text-center",
		iconClass:
			"tw-inline-flex tw-text-2xl tw-text-white tw-transition-all tw-duration-300 group-hover:tw-translate-x-1 lg:tw-hidden xl:tw-inline-flex",
	},
	reviewLink: {
		wrapper: css`
			${tw`mt-0 lg:mt-5 `}
			${companyIndex === 0 ? tw`mt-[80px] lg:mt-4` : tw``}
		`,
		text: css`
			${tw`text-[#359492] hover:underline text-base font-semibold`}
		`,
	},
});
