import { useContext } from "react";
// import selectors
import { getIsServiceBlockLegacyStyled } from "@/selectors";
import { useSelector } from "react-redux";

import { CompanyContext } from "../../CompanyList/CompanyContext";
import CompanyLogo from "./CompanyLogo";
// import components
import LegacyLogoLink from "./LegacyLogoLink";
import UpdatedLogoLink from "./UpdatedLogoLink";

export const LogoSection = () => {
	const { company, companyIndex } = useContext(CompanyContext);
	const isServiceBlockLegacyStyled = useSelector(getIsServiceBlockLegacyStyled);

	return isServiceBlockLegacyStyled ? (
		<LegacyLogoLink company={company} companyIndex={companyIndex}>
			<CompanyLogo company={company} />
		</LegacyLogoLink>
	) : (
		<UpdatedLogoLink company={company}>
			<CompanyLogo company={company} />
		</UpdatedLogoLink>
	);
};

export default LogoSection;
