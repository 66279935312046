import * as constants from "@/config/constants";
import { customBehaviorByPathsWithFilter } from "@/config/customBehaviorByPath";
import {
	MEDIA_ALPHA_BENEFITS_MAP,
	MEDIA_ALPHA_CARRIER_MAPPING,
	MEDIA_ALPHA_MINIMUM_BIDS,
	USE_MEDIA_ALPHA_COMPANY_LOGO,
	USE_MEDIA_ALPHA_HEADLINE,
} from "@/config/media-alpha";
import * as fvyConstants from "@/config/site/favy";
import * as irConstants from "@/config/site/insuranceranked";
import * as tcrConstants from "@/config/site/thecreditreview";
import {
	determineIfFilteringAllowed,
	filterCategoryInfoForDisplay,
	formatMediaAlphaBenefits,
	formatServiceRightColumns,
	getFallbackCompanies,
	removeCharacterEntities,
	resolveMainBenefitsField,
	resolveRatingKey,
	returnImageURL,
	sortCompanies,
} from "@/helpers";
import {
	find,
	identity,
	isEmpty,
	mapObjIndexed,
	mergeAll,
	mergeDeepWith,
	omit,
	path,
	pathOr,
	pick,
	pickBy,
	pipe,
	prop,
	propEq,
	propOr,
	toPairs,
} from "ramda";
import createCachedSelector from "re-reselect";
import { createSelector } from "reselect";

import { DEFAULT_REVIEWS_PATH } from "../../config/constants";
import { FIXED_WIDTH_CARD_VERTICALS } from "../../config/grid";
import * as filters from "../helpers/filters/index";
import { logError } from "../helpers/logError";
import { generateCompanyVisitPath } from "../links/calculateLinkPaths";

export const getStateForSentryExceptions = (state) => ({
	state: JSON.stringify(
		pick(
			[
				constants.MEDIA_ALPHA_FEED,
				constants.GEO_TARGETING,
				constants.MULTISTEP_MODAL,
			],
			state,
		),
		null,
		/* eslint-disable-next-line */
		2,
	),
});

/* Filtering selectors */
export const getIsTransitionApplied = (state) =>
	path([constants.FILTERING, constants.IS_TRANSITION_APPLIED], state);
export const getIsNotUserFiltered = (state) =>
	path([constants.FILTERING, [constants.IS_NOT_USER_FILTERED]], state);
export const getIsNotModalFiltered = (state) =>
	path([constants.FILTERING, constants.IS_NOT_MODAL_FILTERED], state);
export const getLoanAmount = (state) =>
	path([constants.FILTERING, tcrConstants.LOAN_AMOUNT_FILTERING_KEY], state);
export const getCreditQuality = (state) =>
	path([constants.FILTERING, tcrConstants.CREDIT_QUALITY_FILTERING_KEY], state);
export const getFundingType = (state) =>
	path([constants.FILTERING, tcrConstants.FUNDING_TYPE_FILTERING_KEY], state);
export const getMonthlyRevenue = (state) =>
	path(
		[constants.FILTERING, tcrConstants.MONTHLY_REVENUE_FILTERING_KEY],
		state,
	);
export const getExercise = (state) =>
	path([constants.FILTERING, "exercise"], state);
export const getAllFiltering = (state) => prop(constants.FILTERING, state);
export const getAllCompanySpecificQueryParams = (state) =>
	prop(constants.COMPANY_QUERY_PARAMS, state);
export const getModalSelection = (state, props) =>
	path([constants.FILTERING, props.filteringKey], state);
export const getFilteringParametersCount = (state) =>
	path([constants.FILTERING, constants.FILTERING_PARAMETERS_COUNT], state);
export const getPolicyType = (state) =>
	path([constants.FILTERING, irConstants.POLICY_TYPE_FILTERING_KEY], state);
export const getVariationId = (state) =>
	path([constants.FILTERING, constants.VARIATION_ID], state);
export const getMortgageType = (state) =>
	path([constants.FILTERING, fvyConstants.MORTGAGE_TYPE_FILTERING_KEY], state);
export const getGender = (state) =>
	path([constants.FILTERING, fvyConstants.HAIR_GROWTH_FILTERING_KEY], state);

export const getLoanAmountServiced = (state) =>
	path([constants.FILTERING, "loanAmountServiced"], state);

/* Geotargeting selectors */
export const getUserGeo = (state) =>
	path([constants.GEO_TARGETING, "region"], state);
export const getUserZip = (state) =>
	path([constants.GEO_TARGETING, "zip"], state);

/* Vertical Information selectors*/
export const getCategoryArray = (state) =>
	path([constants.VERTICAL_INFORMATION, "categoryArray"], state);
export const getAssets = (state) =>
	path([constants.VERTICAL_INFORMATION, "assets"], state);

/* Routing params / location selectors*/
export const getAllRoutingParams = (state) =>
	path([constants.ROUTING_PARAMS], state);
export const getIsModalUrlParamMatched = (state) =>
	path([constants.ROUTING_PARAMS, "isModalUrlParamMatched"], state);
export const getIsMobileModalUrlParamMatched = (state) =>
	path([constants.ROUTING_PARAMS, "isMobileModalUrlParamMatched"], state);
export const getIsStandardTargetedPath = (state) =>
	pathOr(false, [constants.ROUTING_PARAMS, "isTargetedPath"], state);
export const getIsMediaAlphaTargetedPath = (state) =>
	pathOr(false, [constants.ROUTING_PARAMS, "isMediaAlphaTargetedPath"], state);
export const getIsTargetedPath = createSelector(
	[getIsStandardTargetedPath, getIsMediaAlphaTargetedPath],
	(isStandardTargetedPath, isMediaAlphaTargetedPath) =>
		isStandardTargetedPath || isMediaAlphaTargetedPath,
);
export const getVertical = (state) =>
	path([constants.ROUTING_PARAMS, "vertical"], state);
export const getCompanyPositionOverride = (state) =>
	path([constants.COMPANY_RANKING, "targetedTopCompanyOverride"], state);

export const getVerticalConfig = createSelector([getVertical], (vertical) =>
	pathOr(
		{
			preventRenderingCompanyListHeader: true,
			showFeaturedCompany: true,
		},
		[vertical],
		constants.SITE_INFORMATION,
	),
);

export const getFilter = (state) =>
	path([constants.ROUTING_PARAMS, "filter"], state);
export const getIsBestServicesPath = (state) =>
	path([constants.ROUTING_PARAMS, "isBestServicesPath"], state);
export const getIsMobileTargetedPath = (state) =>
	path([constants.ROUTING_PARAMS, "isMobileTargetedPath"], state);

export const getTargetedSlug = createSelector(
	[getFilter, getIsBestServicesPath],
	(filter, isBestServicesPath) =>
		isBestServicesPath ? "best-services" : filter,
);

/* company selectors */
export const getMediaAlphaCompanies = (state) =>
	path([constants.MEDIA_ALPHA_FEED, "feed", "ads"], state);
export const getContentfulCompanies = (state) =>
	path([constants.VERTICAL_INFORMATION, "companies"], state);
export const getFeaturedCompany = (state) =>
	path([constants.FEATURED_COMPANY, "company"], state);

export const getMediaAlphaMinimumBid = createSelector(getVertical, (vertical) =>
	prop(vertical, MEDIA_ALPHA_MINIMUM_BIDS),
);

export const getUnfilteredCompanies = createSelector(
	[getContentfulCompanies, getMediaAlphaCompanies, getMediaAlphaMinimumBid],
	(contentfulCompanies, mediaAlphaCompanies, mediaAlphaMinimumBid) => {
		if (mediaAlphaCompanies) {
			const companies = contentfulCompanies.map((company) => {
				const matcherProp =
					prop(company.fields.companySlug, MEDIA_ALPHA_CARRIER_MAPPING) ||
					company.fields.companyName;
				const matchedPayloadCompany =
					find(propEq("carrier", matcherProp))(mediaAlphaCompanies) ||
					find(propEq("contentful_company_name", matcherProp))(
						mediaAlphaCompanies,
					);
				if (matchedPayloadCompany) {
					if (!prop("is_direct", matchedPayloadCompany)) {
						// MediaAlpha company.
						return mergeDeepWith(mergeAll, company, {
							fields: { ...matchedPayloadCompany, isMediaAlpha: true },
						});
					} else {
						// Direct partner injection
						const { fields } = company; // destructure fields from company
						const withOmittedFields = omit(
							["isPurelyMediaAlphaPartner", "useMediaAlphaClickUrl"],
							fields,
						);
						const companyWithoutFields = omit(["fields"], company);
						const directPartner = mergeDeepWith(
							mergeAll,
							companyWithoutFields,
							{ fields: { ...withOmittedFields } },
						);
						return mergeDeepWith(mergeAll, directPartner, {
							fields: { ...matchedPayloadCompany },
						});
					}
				} else return company;
			});
			/* if the Contentful company is matched to a MA payload company, return it. Otherwise, only return companies that we have an existing partnership with
      i.e. !isPurelyMediaAlphaPartner
      * */
			const filterByMinimumBid = mediaAlphaMinimumBid
				? filters.filterMediaAlphaByMinimumBid(mediaAlphaMinimumBid)
				: identity;
			return pipe(
				filters.filterAdNetworkCompanies,
				filterByMinimumBid,
			)(companies);
			/* Don't return any companies that we don't have an organic partnership with / are purely ad network partners */
		}

		return filters.filterAdNetworkCompanies(contentfulCompanies);
	},
);

/* Selectors for MediaAlpha */
export const getIsMediaAlphaPublisherVertical = createSelector(
	getVerticalConfig,
	(verticalConfig) =>
		pathOr(false, ["isMediaAlphaPublisherVertical"], verticalConfig),
);

export const getSortCompaniesByMediaAlphaBid = createSelector(
	getVerticalConfig,
	(verticalConfig) =>
		pathOr(false, ["companiesSortedByMediaAlphaBids"], verticalConfig),
);

export const getMultiStepModalConfig = createSelector(
	getVerticalConfig,
	(verticalConfig) => pathOr(false, ["mediaAlphaModalSteps"], verticalConfig),
);

export const getUsesMultiStepModal = createSelector(
	[getVerticalConfig, getMultiStepModalConfig],
	(verticalConfig, multiStepModalConfig) =>
		multiStepModalConfig
			? pathOr(false, ["usesMultiStepModal"], verticalConfig)
			: false,
);

export const getUsesMediaAlphaHeadline = createSelector(
	getVertical,
	(vertical) => propOr(false, vertical, USE_MEDIA_ALPHA_HEADLINE),
);

export const isModalOrFilteringUiFilteredSelector = createSelector(
	[getIsNotModalFiltered, getIsNotUserFiltered],
	(isNotModalFiltered, isNotUserFiltered) =>
		!isNotUserFiltered || !isNotModalFiltered,
);

export const getMobileFilteringModalProperties = createSelector(
	getVerticalConfig,
	(verticalConfig) =>
		path(["filteringInformation", "mobileFilteringModal"], verticalConfig),
);

export const companiesSelector = createSelector(
	[
		getUnfilteredCompanies,
		getContentfulCompanies,
		isModalOrFilteringUiFilteredSelector,
		getLoanAmount,
		getCreditQuality,
		getFundingType,
		getPolicyType,
		getMortgageType,
		getMonthlyRevenue,
		getGender,
		getUserGeo,
		getIsTargetedPath,
		getVertical,
		getFilter,
		getIsMediaAlphaTargetedPath,
		getStateForSentryExceptions,
		getLoanAmountServiced,
	],
	(
		unfilteredCompanies,
		contentfulCompanies,
		isModalOrFilteringUiFiltered,
		loanAmount,
		creditQuality,
		fundingType,
		policyType,
		mortgageType,
		monthlyRevenue,
		gender,
		userGeo,
		isTargetedPath,
		vertical,
		filter,
		isMediaAlphaTargetedPath,
		sentryExceptionsState,
		loanAmountServiced,
	) => {
		const defaultFilteredCompanies =
			filters.filterEligibleAndGeoRelevantCompanies(
				unfilteredCompanies,
				userGeo,
				isMediaAlphaTargetedPath,
			);
		const companies = isTargetedPath
			? defaultFilteredCompanies
			: unfilteredCompanies;

		const applyFilters = (...funcs) => pipe(...funcs)(companies);
		const userFilteredCompanies = applyFilters(
			...[
				filters.filterByCreditQuality(creditQuality),
				filters.filterByLoanAmount(loanAmount),
				filters.filterByFundingType(fundingType),
				filters.filterByMonthlyRevenue(monthlyRevenue),
				filters.filterByPolicyType(policyType),
				filters.filterByMortgageType(mortgageType),
				filters.filterByHairGrowthType(gender),
				filters.filterByLoanAmountServiced(loanAmountServiced),
			],
		);
		const pathFilteredCompanies = applyFilters(
			...pathOr(
				[identity],
				[constants.SITE, vertical, filter, constants.CUSTOM_FILTER],
				customBehaviorByPathsWithFilter,
			),
		);

		const companiesToRender = isModalOrFilteringUiFiltered
			? userFilteredCompanies
			: pathFilteredCompanies;
		const shouldRenderFallbackCompanies = isEmpty(companiesToRender);
		if (shouldRenderFallbackCompanies) {
			return getFallbackCompanies(
				contentfulCompanies,
				vertical,
				sentryExceptionsState,
			);
		} else return companiesToRender;
	},
);

export const sortedCompaniesSelector = createSelector(
	[
		getVertical,
		getFilter,
		getAllFiltering,
		companiesSelector,
		getIsMobileTargetedPath,
		getSortCompaniesByMediaAlphaBid,
		getCompanyPositionOverride,
	],
	(
		vertical,
		filter,
		filteringInformation,
		companies,
		isMobileTargetedPath,
		sortCompaniesByMediaAlphaBid,
		companyPositionOverride,
	) =>
		sortCompanies(
			vertical,
			filter,
			filteringInformation,
			companies,
			isMobileTargetedPath,
			sortCompaniesByMediaAlphaBid,
			companyPositionOverride,
		),
);

export const companySelector = (_, props) => prop("company", props);

const getCompanyCacheKey = (_, props) =>
	-path(["company", "fields", "companyName"], props);

export const getCompanySlug = createSelector(companySelector, (company) =>
	path(["fields", "companySlug"], company),
);

export const getCompanyName = createSelector(companySelector, (company) =>
	path(["fields", "companyName"], company),
);

export const getIsFeaturedCompany = createSelector(companySelector, (company) =>
	pathOr(false, ["fields", "isFeatured"], company),
);

export const getCompanySpecificQueryParams = createSelector(
	[getAllCompanySpecificQueryParams, getCompanyName, getCompanySlug],
	(allCompanySpecificQueryParams, companyName, companySlug) => {
		const companySpecificQueryParams = allCompanySpecificQueryParams.find(
			(obj) => prop(companyName, obj) || prop(companySlug, obj),
		);

		let queryParams = [];
		if (companySpecificQueryParams) {
			if (companySpecificQueryParams[companyName]) {
				queryParams = toPairs(companySpecificQueryParams[companyName]);
			} else if (companySpecificQueryParams[companySlug]) {
				queryParams = toPairs(companySpecificQueryParams[companySlug]);
			}
		}
		return queryParams;
	},
);

export const getFilteringQueryParams = createSelector(
	getAllFiltering,
	(filteringInformation) => {
		/* Whenever a property is added to the initial Redux filtering state, it needs to be added to the restrictedQueryParameters array below
        or it will be appended to visit links */
		const restrictedQueryParameters = [
			constants.IS_TRANSITION_APPLIED,
			constants.IS_NOT_USER_FILTERED,
			constants.IS_NOT_MODAL_FILTERED,
			constants.FILTERING_PARAMETERS_COUNT,
		];
		const filteringInfo = pickBy(
			(value, key) => !restrictedQueryParameters.includes(key),
			filteringInformation,
		);
		return toPairs(
			mapObjIndexed((value) => {
				const isValueAnObject =
					typeof value === "object" && value.hasOwnProperty("value");
				const arrayFormatter = (val) => val.join("-");
				const val = isValueAnObject ? arrayFormatter(value.range) : value;
				const defaultFormatter = (val) =>
					val.toString().toLowerCase().replace(/\s/g, "-");
				return defaultFormatter(val);
			})(filteringInfo),
		);
	},
);

export const getVisitLinkQueryString = createSelector(
	[
		getFilteringQueryParams,
		getCompanySpecificQueryParams,
		getIsFeaturedCompany,
	],
	(filteringQueryParams, companySpecificQueryParams, isFeaturedCompany) => {
		const queryStringArray = [
			...filteringQueryParams,
			...companySpecificQueryParams,
		];
		if (isFeaturedCompany) queryStringArray.push(["ffp", "1"]);
		const queryString = queryStringArray
			.map((property) => property.join("="))
			.join("&");
		return queryString ? `&${queryString}` : queryString;
	},
);

/* Selectors from the Company Category model in Contentful */
export const categoryInfoSelector = createSelector(
	getCategoryArray,
	(categoryArray) => filterCategoryInfoForDisplay(categoryArray),
);

export const getCategoryName = createSelector(
	categoryInfoSelector,
	(categoryInfo) => path(["0", "fields", "categoryName"], categoryInfo),
);

export const getRatingTextParts = createSelector(
	categoryInfoSelector,
	(categoryInfo) => path(["0", "fields", "ratingTextParts"], categoryInfo),
);

export const getExpandServiceBenefitsText = createSelector(
	categoryInfoSelector,
	(categoryInfo) =>
		path(["0", "fields", "expandServiceBenefitsText"], categoryInfo),
);

export const getPhoneCallToActionText = createSelector(
	categoryInfoSelector,
	(categoryInfo) =>
		path(["0", "fields", "phoneCallToActionText"], categoryInfo),
);

export const getIsAmazonVertical = (state) =>
	path([constants.VERTICAL_INFORMATION, "isAmazonVertical"], state);

export const getIsFixedWidthVertical = createSelector(
	getVertical,
	getIsAmazonVertical,
	(vertical, isAmazonVertical) =>
		FIXED_WIDTH_CARD_VERTICALS.includes(vertical) || isAmazonVertical,
);

export const getUseCompanyNameForVisitLink = createSelector(
	getVerticalConfig,
	(verticalConfig) =>
		pathOr(false, ["useCompanyNameForVisitLink"], verticalConfig),
);

export const getLinkCallToActionText = createSelector(
	[categoryInfoSelector, getCompanyName, getUseCompanyNameForVisitLink],
	(categoryInfo, companyName, useCompanyNameForVisitLink) =>
		useCompanyNameForVisitLink
			? `Visit ${companyName}`
			: path(["0", "fields", "linkCallToActionText"], categoryInfo),
);

export const getReviewLinkWithDateText = createSelector(
	categoryInfoSelector,
	(categoryInfo) =>
		path(["0", "fields", "reviewLinkWithDateText"], categoryInfo),
);

export const getReviewLinkWithoutDateText = createSelector(
	categoryInfoSelector,
	(categoryInfo) =>
		path(["0", "fields", "reviewLinkWithoutDateText"], categoryInfo),
);

export const isUserFilterable = createSelector(
	[getIsTargetedPath, getVertical],
	(isTargetedPath, vertical) =>
		determineIfFilteringAllowed(isTargetedPath, vertical),
);

export const getIsServiceBlockLegacyStyled = createSelector(
	getVerticalConfig,
	(verticalConfig) =>
		pathOr(false, ["isServiceBlockLegacyStyled"], verticalConfig),
);

export const getUsesSimplifiedDesign = createSelector(
	getVerticalConfig,
	(verticalConfig) => pathOr(false, ["usesSimplifiedDesign"], verticalConfig),
);

export const getUsesSoftwareDesign = createSelector(
	[getVerticalConfig, getUsesSimplifiedDesign],
	(verticalConfig, usesSimplifiedDesign) =>
		usesSimplifiedDesign ||
		pathOr(false, ["usesSoftwareDesign"], verticalConfig),
);

export const getUsesNewSoftwareDesign = createSelector(
	[getVerticalConfig, getUsesSimplifiedDesign],
	(verticalConfig, usesSimplifiedDesign) =>
		usesSimplifiedDesign ||
		pathOr(false, ["usesNewSoftwareDesign"], verticalConfig),
);

export const getIsProductListingPage = createSelector(
	getVerticalConfig,
	(verticalConfig) => pathOr(false, ["isProductListingPage"], verticalConfig),
);

export const getPreventRenderingSoftwareColumns = createSelector(
	getVerticalConfig,
	(verticalConfig) =>
		pathOr(false, ["preventRenderingSoftwareColumns"], verticalConfig),
);

export const getPreventRenderingSoftwareReviewLink = createSelector(
	getVerticalConfig,
	(verticalConfig) =>
		pathOr(false, ["preventRenderingSoftwareReviewLink"], verticalConfig),
);

export const getUsesProductDescription = createSelector(
	getVerticalConfig,
	(verticalConfig) => pathOr(false, ["usesProductDescription"], verticalConfig),
);

export const getServiceBlockCallOutInformation = createSelector(
	getVerticalConfig,
	(verticalConfig) => path(["serviceBlockCallout"], verticalConfig),
);

export const getCompanyRatingKey = createSelector(
	[getVertical, getFilter, getAllFiltering, getIsMobileTargetedPath],
	(vertical, filter, filteringInformation, isMobileTargetedPath) =>
		resolveRatingKey(
			vertical,
			filter,
			filteringInformation,
			isMobileTargetedPath,
		),
);

export const getIsDefaultColumnConfig = createSelector(
	[getVerticalConfig, getIsServiceBlockLegacyStyled],
	(verticalConfig, isServiceBlockLegacyStyled) =>
		isServiceBlockLegacyStyled
			? false
			: !path(["serviceRightColumns"], verticalConfig),
);

export const getHeaderParts = createSelector(
	[getVerticalConfig, getIsServiceBlockLegacyStyled, getIsDefaultColumnConfig],
	(verticalConfig, isLegacyStyled, isDefaultColumnConfig) => {
		if (isLegacyStyled) {
			return false;
		} else {
			return isDefaultColumnConfig
				? constants.DEFAULT_COMPANY_LIST_HEADER_PARTS
				: pathOr(
						constants.DEFAULT_COMPANY_LIST_HEADER_PARTS,
						["companyListHeaderParts"],
						verticalConfig,
					);
		}
	},
);

export const getMediaAlphaBanner = createSelector(
	getVerticalConfig,
	(verticalConfig) => pathOr(false, ["usesMediaAlphaBanner"], verticalConfig),
);

export const getCouponReplacesReviewCta = createSelector(
	getVerticalConfig,
	(verticalConfig) =>
		pathOr(false, ["useCouponInsteadOfReviewCta"], verticalConfig),
);

export const getUsesGridLayout = createSelector(
	getVerticalConfig,
	(verticalConfig) => pathOr(false, ["usesGridLayout"], verticalConfig),
);

/* Selectors pulling from individual Company fields */
const getCompanyIndex = (_, props) => prop("companyIndex", props);

export const getProductDescription = createCachedSelector(
	[companySelector, getUsesProductDescription],
	(company, usesProductDescription) =>
		usesProductDescription
			? pathOr(false, ["fields", "productDescription"], company)
			: false,
)(getCompanyCacheKey);

export const getPromotionText = createCachedSelector(
	[companySelector],
	(company) => path(["fields", "promotionText"], company),
)(getCompanyCacheKey);

export const getIsMediaAlphaCompany = createCachedSelector(
	[companySelector],
	(company) => pathOr(false, ["fields", "isMediaAlpha"], company),
)(getCompanyCacheKey);

export const getIsDirectCompanyFromPayload = createCachedSelector(
	[companySelector],
	(company) => pathOr(false, ["fields", "is_direct"], company),
)(getCompanyCacheKey);

export const getMediaAlphaHeadline = createCachedSelector(
	[
		companySelector,
		getIsMediaAlphaPublisherVertical,
		getUsesMediaAlphaHeadline,
		getIsMediaAlphaCompany,
	],
	(
		company,
		isMediaAlphaPublisherVertical,
		usesMediaAlphaHeadline,
		isMediaAlphaCompany,
	) => {
		const isHeadlineAllowed =
			isMediaAlphaPublisherVertical && usesMediaAlphaHeadline;
		if (isHeadlineAllowed) {
			if (isMediaAlphaCompany) {
				const mediaAlphaHeadline = isHeadlineAllowed
					? pathOr(false, ["fields", "headline"], company)
					: false;
				return mediaAlphaHeadline
					? removeCharacterEntities(mediaAlphaHeadline)
					: mediaAlphaHeadline;
			} else {
				return pathOr(false, ["fields", "companyHeadline"], company);
			}
		} else return false;
	},
)(getCompanyCacheKey);

export const getUsesMediaAlphaBenefits = createSelector(
	[getIsMediaAlphaPublisherVertical, getIsMediaAlphaCompany, getVertical],
	(isMediaAlphaPublisherVertical, isMediaAlphaCompany, vertical) =>
		isMediaAlphaPublisherVertical &&
		propOr(false, vertical, MEDIA_ALPHA_BENEFITS_MAP) &&
		isMediaAlphaCompany,
);

export const getCompanySlugForVisitPath = createSelector(
	[getCompanySlug, getIsMediaAlphaTargetedPath],
	(companySlug, isMediaAlphaTargetedPath) => {
		if (isMediaAlphaTargetedPath) {
			return `${companySlug}-ma`;
		}

		return companySlug;
	},
);

export const getCouponCode = createCachedSelector(
	[companySelector],
	(company) => path(["fields", "couponCode"], company),
)(getCompanyCacheKey);

export const getCouponDescription = createCachedSelector(
	[companySelector],
	(company) => path(["fields", "couponDescription"], company),
)(getCompanyCacheKey);

export const getUsesNonSoftwareCoupon = createSelector(
	[
		getCouponCode,
		getCouponDescription,
		getUsesNewSoftwareDesign,
		getCouponReplacesReviewCta,
	],
	(
		couponCode,
		couponDescription,
		usesNewSoftwareDesign,
		couponReplacesReviewCta,
	) =>
		couponReplacesReviewCta
			? !!(couponDescription && !usesNewSoftwareDesign)
			: !!(couponCode && !usesNewSoftwareDesign),
);

export const getCompanyRating = createCachedSelector(
	[getCompanyRatingKey, companySelector],
	(ratingKey, company) => path(["fields", ratingKey], company),
)(getCompanyCacheKey);

export const getAwardText = createCachedSelector([companySelector], (company) =>
	path(["fields", "awardText"], company, null),
)(getCompanyCacheKey);

export const getAmBestRating = createCachedSelector(
	[companySelector],
	(company) => path(["fields", "amBestRating"], company, null),
)(getCompanyCacheKey);

export const getPrice = createCachedSelector([companySelector], (company) =>
	path(["fields", "price"], company),
)(getCompanyCacheKey);

export const getRevampedAwardText = createSelector(
	[getIsServiceBlockLegacyStyled, getAwardText],
	(isServiceBlockLegacyStyled, awardText) =>
		awardText && !isServiceBlockLegacyStyled ? awardText : null,
);

export const getIsAwardTextAllowed = createSelector(
	[getCompanyIndex, getVerticalConfig],
	(companyIndex, verticalConfig) => {
		const isAllowedForAllCompanies = path(
			["allowAwardTextOnAllCompanies"],
			verticalConfig,
		);
		// eslint-disable-next-line no-magic-numbers
		return isAllowedForAllCompanies ?? [0, 1].includes(companyIndex);
	},
);

export const getMainBenefits = createCachedSelector(
	[
		getVertical,
		getFilter,
		getIsMobileTargetedPath,
		getAllFiltering,
		companySelector,
		getUsesMediaAlphaBenefits,
	],
	(
		vertical,
		filter,
		isMobileTargetedPath,
		filteringInformation,
		company,
		usesMediaAlphaBenefits,
	) => {
		const nonMediaAlphaBenefitsField = resolveMainBenefitsField(
			vertical,
			filter,
			isMobileTargetedPath,
			filteringInformation,
			company,
		);
		const mediaAlphaBenefits = path(["fields", "description"], company);
		if (usesMediaAlphaBenefits) {
			return formatMediaAlphaBenefits(mediaAlphaBenefits);
		}

		return removeCharacterEntities(
			pathOr(
				path(["fields", "companyMainBenefits"], company),
				["fields", nonMediaAlphaBenefitsField],
				company,
			),
		);
	},
)(getCompanyCacheKey);

const getDefaultColumnConfig = createSelector(
	getVerticalConfig,
	(verticalConfig) =>
		pathOr(
			constants.DEFAULT_SERVICE_BLOCK_COLUMNS,
			["serviceRightColumns"],
			verticalConfig,
		),
);

export const getCompanyListHeaderColumnCount = createSelector(
	getDefaultColumnConfig,
	(defaultColumnConfig) => defaultColumnConfig.length,
);

export const getServiceBlockColumnConfig = createSelector(
	[getIsServiceBlockLegacyStyled, getDefaultColumnConfig],
	(isServiceBlockLegacyStyled, defaultColumnConfig) => {
		if (isServiceBlockLegacyStyled) return false;
		return defaultColumnConfig;
	},
);

export const getColumnInformation = createCachedSelector(
	[getVertical, getServiceBlockColumnConfig, companySelector],
	(vertical, columnConfig, company) =>
		columnConfig
			? formatServiceRightColumns(company, columnConfig)
			: columnConfig,
)(getCompanyCacheKey);

export const getProductDescriptionHeadline = createCachedSelector(
	[
		companySelector,
		getProductDescription,
		getCompanyName,
		getUsesSoftwareDesign,
	],
	(company, productDescription, companyName, usesSoftwareDesign) => {
		const productDescriptionHeadline = path(
			["fields", "productDescriptionHeadline"],
			company,
		);
		const defaultHeadline =
			productDescription && !usesSoftwareDesign
				? `About ${companyName}`
				: "Description";
		return productDescriptionHeadline || defaultHeadline;
	},
)(getCompanyCacheKey);

export const getServiceBlockColumnCount = createSelector(
	getServiceBlockColumnConfig,
	(columnConfig) => columnConfig.length,
);

export const getPreventRenderingColumns = createSelector(
	getVerticalConfig,
	(verticalConfig) =>
		pathOr(false, ["preventRenderingColumns"], verticalConfig),
);

export const getShowFeaturedCompany = createSelector(
	getVerticalConfig,
	(verticalConfig) => pathOr(false, ["showFeaturedCompany"], verticalConfig),
);

export const getPreventRenderingCompanyListHeader = createSelector(
	[getVerticalConfig, getUsesSoftwareDesign],
	(verticalConfig, usesSoftwareDesign) =>
		usesSoftwareDesign
			? true
			: pathOr(false, ["preventRenderingCompanyListHeader"], verticalConfig),
);

export const getUsesMediaAlphaCompanyLogo = createSelector(
	[getIsMediaAlphaPublisherVertical, getIsMediaAlphaCompany, getVertical],
	(isMediaAlphaPublisherVertical, isMediaAlphaCompany, vertical) =>
		isMediaAlphaPublisherVertical && isMediaAlphaCompany
			? propOr(false, vertical, USE_MEDIA_ALPHA_COMPANY_LOGO)
			: false,
);

export const getCompanyLogoUrl = createCachedSelector(
	[
		getAssets,
		companySelector,
		getUsesMediaAlphaCompanyLogo,
		getVertical,
		getStateForSentryExceptions,
	],
	(
		assets,
		company,
		usesMediaAlphaCompanyLogo,
		vertical,
		sentryExceptionState,
	) => {
		/* use large image URL, fallback to medium if large isn't available. Observed no large_image_url for farmers on auto insurance */
		const mediaAlphaLogoUrl = pathOr(
			path(["fields", "medium_image_url"], company),
			["fields", "large_image_url"],
			company,
		);
		const assetId = path(["fields", "companyLogo", "sys", "id"], company);
		const contentfulLogoUrl = returnImageURL(assets, assetId);
		if (
			usesMediaAlphaCompanyLogo &&
			!mediaAlphaLogoUrl &&
			!["lifeplans.com"].includes(company.fields.carrier)
		) {
			// use the array to prevent reporting Sentry company logo errors for a specific MA carrier
			logError(
				`No medium or large company image in MA payload for ${company.fields.carrier} on ${vertical}. Using company image from Contentful`,
			);
		}
		/* fallback logic to use Contentful image URL if for whatever reason the Media Alpha logo isn't available */
		if (usesMediaAlphaCompanyLogo && mediaAlphaLogoUrl) {
			return mediaAlphaLogoUrl;
		}

		return contentfulLogoUrl;
	},
)(getCompanyCacheKey);

export const getCompanyPhoneNumber = createCachedSelector(
	[companySelector],
	(company) => path(["fields", "phoneNumber"], company),
)(getCompanyCacheKey);

export const getCompanyPhoneNumberHref = createCachedSelector(
	[getCompanyPhoneNumber],
	(phoneNumber) =>
		phoneNumber ? `tel:+1${phoneNumber.replace(/ /g, "")}` : phoneNumber,
)(getCompanyCacheKey);

export const getTaxCafNumber = createCachedSelector(
	[companySelector],
	(company) => path(["fields", "cafNumber"], company),
)(getCompanyCacheKey);

/* Review widget selectors */
export const getTrustpilotScore = createCachedSelector(
	[companySelector],
	(company) => path(["fields", "trustpilotReviewScore"], company),
)(getCompanyCacheKey);

export const getWebReviewScore = createCachedSelector(
	[companySelector],
	(company) => path(["fields", "webReviewScore"], company),
)(getCompanyCacheKey);

export const getReviewWidgetsConfig = createSelector(
	getVerticalConfig,
	(verticalConfig) => path(["reviewWidgets"], verticalConfig),
);

export const getIsTrustpilotWidgetRendered = createSelector(
	getReviewWidgetsConfig,
	(reviewWidgetsConfig) =>
		["trustpilot", "all"].includes(prop("widget", reviewWidgetsConfig)),
);

export const getIsWebReviewWidgetRendered = createSelector(
	getReviewWidgetsConfig,
	(reviewWidgetsConfig) =>
		["webReview", "all"].includes(prop("widget", reviewWidgetsConfig)),
);

export const getUsesNewWebReviewWidget = createSelector(
	[getReviewWidgetsConfig, getIsWebReviewWidgetRendered],
	(reviewWidgetsConfig, isWebReviewWidgetRendered) =>
		isWebReviewWidgetRendered &&
		prop("webReviewDesign", reviewWidgetsConfig) === "newNonSoftware",
);

export const getIsTcrReviewWidgetRendered = createSelector(
	getReviewWidgetsConfig,
	(reviewWidgetsConfig) =>
		["tcrReviewWidget"].includes(prop("widget", reviewWidgetsConfig)),
);

export const getIsAnyReviewWidgetRendered = createSelector(
	[
		getReviewWidgetsConfig,
		getUsesSoftwareDesign,
		getIsTrustpilotWidgetRendered,
	],
	(reviewWidgetsConfig, usesSoftwareDesign, rendersTrustpilotWidget) => {
		if (usesSoftwareDesign && !rendersTrustpilotWidget) {
			return false;
		} else
			return ["webReview", "trustpilot", "tcrReviewWidget", "all"].includes(
				prop("widget", reviewWidgetsConfig),
			);
	},
);

export const getAreAllReviewWidgetsRendered = createSelector(
	getReviewWidgetsConfig,
	(reviewWidgetsConfig) => prop("widget", reviewWidgetsConfig) === "all",
);

export const getTrustpilotWhitelistThreshold = createSelector(
	getReviewWidgetsConfig,
	(reviewWidgetsConfig) =>
		prop("trustpilotWhitelistThreshold", reviewWidgetsConfig),
);

export const getTrustpilotReviewCount = createCachedSelector(
	[companySelector],
	(company) => path(["fields", "trustpilotReviewCount"], company),
)(getCompanyCacheKey);

export const getWebReviewCount = createCachedSelector(
	[companySelector],
	(company) => path(["fields", "webReviewCount"], company),
)(getCompanyCacheKey);

/* Selector for link outs */
export const getIsOnlyAllowedToRenderVisitLinks = createSelector(
	getVerticalConfig,
	(verticalConfig) =>
		pathOr(false, ["isOnlyAllowedToRenderVisitLinks"], verticalConfig),
);

export const getIsVisitLinkingProhibited = createCachedSelector(
	[companySelector],
	(company) => !!path(["fields", "prohibitReactAppVisitLinks"], company),
)(getCompanyCacheKey);

export const getIsAllLinkingProhibited = createCachedSelector(
	[getVerticalConfig, companySelector],
	(verticalConfig, company) =>
		!!path(["fields", "prohibitAllReactAppLinks"], company) ||
		pathOr(false, ["isAllLinkingProhibited"], verticalConfig),
)(getCompanyCacheKey);

export const getIsRevampedPhoneLinkUsed = createSelector(
	getVerticalConfig,
	(verticalConfig) => pathOr(false, ["usesRevampedPhoneLink"], verticalConfig),
);

export const getIsReviewLinkProhibited = createSelector(
	[
		getVerticalConfig,
		getUsesSoftwareDesign,
		getPreventRenderingSoftwareReviewLink,
	],
	(verticalConfig, usesSoftwareDesign, preventRenderingSoftwareReviewLink) =>
		usesSoftwareDesign
			? preventRenderingSoftwareReviewLink
			: pathOr(false, ["preventRenderingReviewLink"], verticalConfig),
);

export const getIsCompanyReviewLive = createCachedSelector(
	[companySelector],
	(company) =>
		!!(
			path(["fields", "companyReviewArticle"], company) ||
			path(["fields", "companyReview"], company)
		),
)(getCompanyCacheKey);

export const getIsCompanyReviewLinkable = createCachedSelector(
	[companySelector, getIsCompanyReviewLive, getIsAllLinkingProhibited],
	(company, isCompanyReviewLive, isAllLinkingProhibited) =>
		isCompanyReviewLive && !isAllLinkingProhibited,
)(getCompanyCacheKey);

export const getUsePhoneLinkInsteadOfVisitLink = createCachedSelector(
	[getIsVisitLinkingProhibited, getCompanyPhoneNumber],
	(isVisitLinkProhibited, phoneNumber) =>
		isVisitLinkProhibited && !!phoneNumber,
)(getCompanyCacheKey);

export const getAreTwoServiceRightFooterButtonsRendered = createCachedSelector(
	[
		companySelector,
		getCompanyPhoneNumber,
		getIsRevampedPhoneLinkUsed,
		getIsVisitLinkingProhibited,
	],
	(company, phoneNumber, isRevampedPhoneLinkUsed, isVisitLinkingProhibited) =>
		!!phoneNumber && !!isRevampedPhoneLinkUsed && !isVisitLinkingProhibited,
)(getCompanyCacheKey);

export const getAlternativeReviewSlug = createCachedSelector(
	[companySelector],
	(company) => path(["fields", "companyReviewSlug"], company),
)(getCompanyCacheKey);

export const getReviewSlug = createCachedSelector(
	[getCompanySlug, getAlternativeReviewSlug],
	(companySlug, alternativeReviewSlug) => alternativeReviewSlug || companySlug,
)(getCompanyCacheKey);

export const getCompanyReviewPath = createCachedSelector(
	[getVertical, getReviewSlug],
	(vertical, reviewSlug) =>
		`/${vertical}/${pathOr(
			DEFAULT_REVIEWS_PATH,
			["criticalOperationProperties", "reviewPathParam"],
			constants.SITE_INFORMATION,
		)}/${reviewSlug}`,
)(getCompanyCacheKey);

export const getIntegratedPublisherVisitPath = createSelector(
	[companySelector, getVariationId],
	(company, variationId) => {
		const baseUrl = path(["fields", "click_url"], company);
		const queryString = variationId
			? `?${constants.VARIATION_ID}=${variationId}`
			: "";
		return `${baseUrl}${queryString}`;
	},
);

export const getUsesMediaAlphaClickUrl = createCachedSelector(
	companySelector,
	(company) => pathOr(false, ["fields", "useMediaAlphaClickUrl"], company),
)(getCompanyCacheKey);

export const getCompanyVisitPath = createCachedSelector(
	[
		getIsMediaAlphaCompany,
		getIsTargetedPath,
		getCompanySlugForVisitPath,
		getAllRoutingParams,
		getVisitLinkQueryString,
		isModalOrFilteringUiFilteredSelector,
		getIsNotModalFiltered,
		getCompanyIndex,
		getUsesMediaAlphaClickUrl,
		getIntegratedPublisherVisitPath,
	],
	(
		isMediaAlphaCompany,
		isTargetedPath,
		companySlugForVisitPath,
		allRoutingParams,
		visitLinkQueryString,
		isModalOrFilteringUiFiltered,
		isNotModalFiltered,
		companyIndex,
		usesMediaAlphaClickUrl,
		integratedPublisherVisitPath,
	) => {
		const { vertical, filter, isBestServicesPath } = allRoutingParams;
		if (isMediaAlphaCompany && usesMediaAlphaClickUrl) {
			return integratedPublisherVisitPath;
		} else {
			return generateCompanyVisitPath({
				vertical,
				isTargeted: isTargetedPath,
				isModalOrFilteringUiFiltered,
				visitLinkQueryString,
				filter,
				companySlug: companySlugForVisitPath,
				rank: companyIndex + 1,
				isBestServicesPath,
				isNotModalFiltered,
			});
		}
	},
)(getCompanyCacheKey);
