// import components
import HeaderLink from "@/components/ServiceBlock/ServiceRight/HeaderLink";
import withOutboundPaths from "@/links/withOutboundPaths";
// import selectors
import { getIsServiceBlockLegacyStyled } from "@/selectors";
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

const styles = css`
	margin: 0;

	a {
		color: #333742;
		font-size: 1.375rem;
	}
`;

export const ServiceRightHeader = ({
	company,
	companyReviewPath,
	companyVisitPath,
	isCompanyReviewLinkable,
	isAllLinkingProhibited,
}) => {
	const isServiceBlockLegacyStyled = useSelector(getIsServiceBlockLegacyStyled);

	return isServiceBlockLegacyStyled ? (
		<h3 css={styles}>
			<HeaderLink
				companyReviewPath={companyReviewPath}
				isCompanyReviewLinkable={isCompanyReviewLinkable}
				isAllLinkingProhibited={isAllLinkingProhibited}
				companyVisitPath={companyVisitPath}
				company={company}
			/>
		</h3>
	) : null;
};

export default withOutboundPaths(ServiceRightHeader);

ServiceRightHeader.propTypes = {
	company: PropTypes.object.isRequired,
	companyIndex: PropTypes.number.isRequired,
	companyReviewPath: PropTypes.string.isRequired,
	companyVisitPath: PropTypes.string.isRequired,
	isCompanyReviewLinkable: PropTypes.bool.isRequired,
	isAllLinkingProhibited: PropTypes.bool.isRequired,
};
