import CompanyBenefitsLinkWrapper from "@/components/ServiceBlock/ServiceRight/CompanyBenefitsLinkWrapper";
import {
	ALWAYS_DISPLAYED_MOBILE_BENEFITS_COUNT,
	FVY_DOMAIN,
	IR_DOMAIN,
	SITE,
	TCR_DOMAIN,
} from "@/config/constants";
import {
	FLOWER_DELIVERY_PATH,
	MATTRESS_TOPPERS_PATH,
	MEAT_DELIVERY_SERVICES_PATH,
	PET_FOOD_DELIVERY_PATH,
	SOLAR_PATH,
	SURVEY_SITES_PATH,
	WINE_CLUBS_PATH,
} from "@/config/site/favy";
import {
	FINAL_EXPENSE_INSURANCE_PATH,
	IR_AUTO_INSURANCE_DEFAULT_PATH,
	IR_HOMEOWNERS_INSURANCE_DEFAULT_PATH,
	MEDICARE_ADVANTAGE_PATH,
	MEDICARE_SUPPLEMENT_PATH,
} from "@/config/site/insuranceranked";
import { FINANCIAL_ADVISORS_PATH } from "@/config/site/thecreditreview";
import {
	getCompanyName,
	getIsMediaAlphaPublisherVertical,
	getIsServiceBlockLegacyStyled,
	getMainBenefits,
	getProductDescription,
	getUsesSimplifiedDesign,
	getVertical,
} from "@/selectors";
import PropTypes from "prop-types";
import { path } from "ramda";
import { useSelector } from "react-redux";
import tw, { css } from "twin.macro";

const styles = {
	benefitsList: {
		revamped: css`
      margin: 0;
      max-width: 305px;
      padding-left: 0;
      list-style: none;

      @media (max-width: 991px) {
        max-width: 100%;
        margin-bottom: 5px;
      }

      ul {
        margin-top: 0;
        padding-left: 0;
      }

      li, ul li {
        list-style-type: none;
        padding-left: 25px;
        background-image: url("/react-images/common/iconTick.svg");
        background-repeat: no-repeat;
        background-position: left top 5px;
        margin-bottom: 6px;
        color: #333;
        font-size: 14px;
        }

        @media (max-width: 1199px) {
          font-size: 13px;
        }

        @media (max-width: 991px) {
          font-size: 14px;
        }


				},
      }`,
		legacy: css`
			padding-left: 0;
			list-style: none;
			margin-top: 15px;
			margin-bottom: 15px;

			ul {
				margin-top: 0;
			}

			li {
				padding-left: 25px;
				background-image: url("/react-images/common/iconTick.svg");
				background-repeat: no-repeat;
				background-position: left top 5px;
				margin-bottom: 6px;
				color: #333;
				font-size: 14px;

				@media (max-width: 991px) {
					font-size: 16px;
				}
			}
		`,
		[IR_DOMAIN]: {
			[IR_AUTO_INSURANCE_DEFAULT_PATH]: tw`w-full max-w-none`,
			[IR_HOMEOWNERS_INSURANCE_DEFAULT_PATH]: tw`w-full max-w-none`,
			[FINAL_EXPENSE_INSURANCE_PATH]: tw`w-full max-w-none`,
			[MEDICARE_ADVANTAGE_PATH]: tw`w-full max-w-none`,
			[MEDICARE_SUPPLEMENT_PATH]: tw`w-full max-w-none`,
		},
		[FVY_DOMAIN]: {
			allVerticals: css`
				max-width: none;
				min-width: 340px;
				display: block;

				ul li,
				li {
					padding-left: 20px;
					background-image: url("/react-images/common/favyCheck.svg");
					background-position: left top 11px;
					${tw`text-sm leading-6 text-gray-900 flex-1 lg:text-base lg:leading-7`},
				}

				@media (max-width: 1024px) {
					margin-bottom: 16px;
				}

				@media (max-width: 991px) {
					min-width: unset;
					max-width: 340px;
				}

				@media (max-width: 768px) {
					margin-bottom: 32px;
				}

				@media (max-width: 500px) {
					min-width: auto;
				}
			`,
			[WINE_CLUBS_PATH]: css`
				@media (min-width: 991px) {
					max-width: 340px;
					min-width: unset;
				}
			`,
			[MEAT_DELIVERY_SERVICES_PATH]: css`
				@media (min-width: 1024px) {
					min-width: unset;
				}

				@media (min-width: 991px) {
					max-width: 285px;
				}
				@media (min-width: 991px) and (max-width: 1024px) {
					min-width: 225px;
				}
			`,
			[PET_FOOD_DELIVERY_PATH]: css`
				@media (min-width: 1024px) {
					width: 350px;
				}
			`,
			[SURVEY_SITES_PATH]: css`
				@media (min-width: 1024px) {
					min-width: 360px;
				}
			`,
			[SOLAR_PATH]: css`
				@media (min-width: 1024px) {
					min-width: unset;
					width: 340px;
				}
			`,
			[MATTRESS_TOPPERS_PATH]: css`
				@media (min-width: 1024px) {
					min-width: unset;
					max-width: unset;
					width: 380px;
				}
			`,
			[FLOWER_DELIVERY_PATH]: css`
				@media (min-width: 1024px) {
					min-width: unset;
					max-width: 340px;
				}
			`,
		},
		[TCR_DOMAIN]: {
			allVerticals: css`
				li {
					color: #48484b;
					font-weight: 600;
				}
			`,
			[FINANCIAL_ADVISORS_PATH]: css`
				@media (min-width: 991px) {
					max-width: unset;
					min-width: unset;
					margin-right: 2rem;
				}
			`,
		},
	},
};

export const CompanyBenefitsList = (props) => {
	const { company, companyIndex, serviceContentDisplayStyle, children } = props;
	const companyName = useSelector((state) => getCompanyName(state, props));

	const companyMainBenefits = useSelector((state) =>
		getMainBenefits(state, props),
	);
	const isMediaAlphaPublisherVertical = useSelector(
		getIsMediaAlphaPublisherVertical,
	);
	const companyBenefits = companyMainBenefits.map((benefit, idx) => (
		<li key={idx}>{benefit}</li>
	));
	const shouldRenderAllBenefits =
		companyIndex === 0 || isMediaAlphaPublisherVertical;
	const isServiceBlockLegacyStyled = useSelector(getIsServiceBlockLegacyStyled);
	const vertical = useSelector(getVertical);
	const productDescription = useSelector((state) =>
		getProductDescription(state, { company }),
	);
	const usesSimplifiedDesign = useSelector(getUsesSimplifiedDesign);

	const limitedBenefitsList = (
		<ul
			css={[
				isServiceBlockLegacyStyled
					? styles.benefitsList.legacy
					: styles.benefitsList.revamped,
				path(["benefitsList", SITE, vertical], styles),
				path(["benefitsList", SITE, "allVerticals"], styles),
				usesSimplifiedDesign &&
					css`
						@media (min-width: 1024px) {
							min-width: unset;
							max-width: 340px;
						}
					`,
			]}
		>
			{companyBenefits.slice(0, ALWAYS_DISPLAYED_MOBILE_BENEFITS_COUNT)}
			<ul style={{ display: serviceContentDisplayStyle }}>
				{companyBenefits.slice(ALWAYS_DISPLAYED_MOBILE_BENEFITS_COUNT)}
			</ul>
		</ul>
	);

	const fullBenefitsList = (
		<ul
			css={[
				isServiceBlockLegacyStyled
					? styles.benefitsList.legacy
					: styles.benefitsList.revamped,
				path(["benefitsList", SITE, vertical], styles),
				path(["benefitsList", SITE, "allVerticals"], styles),
				usesSimplifiedDesign &&
					css`
						@media (min-width: 1024px) {
							min-width: unset;
							max-width: 340px;
						}
					`,
			]}
		>
			{companyBenefits}
		</ul>
	);

	const BenefitsList = () => {
		const list = shouldRenderAllBenefits
			? fullBenefitsList
			: limitedBenefitsList;
		const favyProductHeading = (
			<p
				className={`tw-pb-2 tw-text-lg tw-font-bold tw-leading-6 tw-tracking-tight tw-text-gray-900 lg:tw-pb-3 lg:tw-text-xl`}
			>
				{companyName}
			</p>
		);
		return (
			<>
				{children}
				{[FVY_DOMAIN].includes(SITE) && !productDescription
					? favyProductHeading
					: null}
				{list}
			</>
		);
	};

	const LinkWrappedBenefits = () => (
		<CompanyBenefitsLinkWrapper company={company} companyIndex={companyIndex}>
			<BenefitsList />
		</CompanyBenefitsLinkWrapper>
	);

	return isServiceBlockLegacyStyled ? (
		<BenefitsList />
	) : (
		<LinkWrappedBenefits />
	);
};

export default CompanyBenefitsList;

CompanyBenefitsList.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
	companyIndex: PropTypes.number.isRequired,
	company: PropTypes.object.isRequired,
	serviceContentDisplayStyle: PropTypes.string.isRequired,
	variant: PropTypes.string,
};
